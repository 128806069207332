import { cn } from '~/utils/css/css.ts';

import * as React from 'react';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string[];
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, ...props }, ref) => {
    return (
      <span className={'space-y-0.5'}>
        <input
          type={type}
          className={cn(
            'flex h-9 w-full rounded-sm border-input/80 border-0.5 bg-transparent px-3 py-1.5 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
            error && 'border-destructive',
          )}
          ref={ref}
          {...props}
        />
        <span className={'text-xs text-destructive'}>{error}</span>
      </span>
    );
  },
);
Input.displayName = 'Input';

export { Input };
